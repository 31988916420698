import React, {useEffect, useState} from "react";
import "./css/JoinMediaSignIn.scss";
import message from "@const/message";
import MediaAPI from "@api/media";
import {CompanyInfoListType} from "@models/companyInfo";
import Pagination from "@components/common/Pagination";
import qs from "query-string";
import {GetListType} from "@components/common/interface/GetListType";
import {GetArticleOptionType, ListType} from "@models/article";
import {useLocation} from "react-router";
import withList from "@components/common/hoc/withList";
interface Props extends GetListType<ListType> {
    title: string
}

function JoinMediaSignIn(props: Props) {
    const location = useLocation();
    const page = Number(qs.parse(location.search).page?.toString() || 1);
    const [inputKeyword, setInputKeyword] = useState(props.keyword);

    useEffect(() => {
        getList();
    }, [page]);

    const getList = () => {
        let params: GetArticleOptionType = {
            page: page,
            count: props.count,
            keyword: "",
        };
        if (props.keyword) {
            params.keyword = props.keyword;
            params.option = props.searchType;
        }
        MediaAPI.getMediaList(params).then((res: CompanyInfoListType) => props.setList(res));
    };

    const onClickJoin = () => {
        if (window.confirm(message.joinRequest)) {
            MediaAPI.joinMedia().then(() => alert(message.joinRequestSuccess));
        }
    }

    return (
        <div id="JoinMediaSignIn">
            <div className="page_title">{props.title}</div>
            <div className="search_area">
                <input type="text"
                       placeholder={message.requireInput}
                       value={inputKeyword}
                       onChange={e => setInputKeyword(e.target.value)}
                       onKeyUp={e => e.key === 'Enter' && props.searchList(inputKeyword)}
                />
                <button className="icon_search ir_txt" onClick={() => props.searchList(inputKeyword)}>검색 버튼</button>
            </div>
            <table>
                <colgroup>
                    <col width="20%"/>
                    <col width="60%"/>
                    <col width="20%"/>
                </colgroup>
                <thead>
                    <tr>
                        <th/>
                        <th>미디어명</th>
                        <th>가입 신청</th>
                    </tr>
                </thead>
                <tbody>
                {
                    props.list.items?.filter((item: any) => item.media_logo_key).map((item: any) => {
                        return (
                            <tr>
                                <td>
                                    <img src={window.$Global.getImageCDN(item.media_logo_key)}/>
                                </td>
                                <td>
                                    <h2>{item.media_name}</h2>
                                </td>
                                <td>
                                    <button className="btn_join" onClick={onClickJoin}>가입 신청하기</button>
                                </td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>

            <Pagination curPage={page} lastNum={props.list.last} onClick={props.changePage} />
        </div>
    )
}

export default withList(JoinMediaSignIn);