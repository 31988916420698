import React, {useState} from "react";
import {Link} from "react-router-dom";
import "./css/AnnouncementBoard.scss";
import {ChildType} from "@models/home";
import {ItemType} from "@models/article";
import {getSectionURL} from "../common/js/getSectionURL";
import AnnouncementBoardTable from "./AnnouncementBoardTable";

interface Props {
    list: ChildType
}

function AnnouncementBoard({list}: Props) {
    const [tabActive, setTabActive] = useState(0);
    const sectionURL = getSectionURL(list?.idx, list?.child[tabActive]?.idx);
    const hasChild = list?.child.length > 0;
    let viewList:ItemType[] = [];

    if (hasChild) {
        viewList = list?.child[tabActive].article;
    }

    return (
        <div id="AnnouncementBoard">
            <div className="board_head">
                <div className="sort_area">
                {
                    list?.child.map((item, idx) => <button key={idx} className={tabActive === idx ? 'active' : ''} onClick={() => setTabActive(idx)}>{item.name}</button>)
                }
                </div>
                <Link to={sectionURL} className="btn_more"/>
            </div>
            <AnnouncementBoardTable list={viewList}/>
        </div>
    )
}

export default AnnouncementBoard;