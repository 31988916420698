import { configureStore } from '@reduxjs/toolkit'
import sectionReducer from '../features/section/sectionSlice'
import memberReducer from '../features/member/memberSlice'
import mediaReducer from '../features/media/mediaSlice'

export const store = configureStore({
	reducer: {
		section: sectionReducer,
		member: memberReducer,
		mediaInfo: mediaReducer
	},
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch