import React, {useEffect, useState} from "react";
import "./css/Footer.scss";
import {Link} from "react-router-dom";
import MediaAPI from "@api/media";
import {CompanyInfoType} from "@models/companyInfo";
import {useDispatch} from "react-redux";
import {setMediaInfo} from "@features/media/mediaSlice";

function Footer() {
    const dispatch = useDispatch();
    const [footerInfo, setFooterInfo] = useState<CompanyInfoType>({} as CompanyInfoType);
    const [footerStyle, setFooterStyle] = useState({});

    useEffect(() => {
        MediaAPI.getMediaInfo().then((res: CompanyInfoType) => {
            dispatch(setMediaInfo(res));
            setFooterInfo(res);
        })
    }, []);

    useEffect(() => {
        setFooterStyle(window.$Global.sizeCal(window.$Global.getImageCDN(footerInfo.media_logo_key), 60));
    }, [footerInfo]);

    return (
        <footer>
            <div className="footer_area">
                <div className="info_area">
                    <img className="logo" src={window.$Global.getImageCDN(footerInfo.media_logo_key)} style={footerStyle}/>
                    <div className="info">
                        주소 : {`${footerInfo.address || ''} ${footerInfo.address_detail || ''}`} | 대표전화 : {footerInfo.company_phone} |<br/>
                        법인명 : {footerInfo.company_name} | 미디어명 : {footerInfo.media_name}  |  발행일 : {window.$Global.convertDate(footerInfo.media_created_at)}<br/>
                        발행인 : {footerInfo.issuer_name}  |  편집인 : {footerInfo.editor_name}  |  청소년보호책임자 : {footerInfo.ypm_name}
                    </div>
                </div>

                <div className="desc_area">
                    <div className="link">
                        <Link to="#">서비스 이용약관</Link>
                        <Link to="#">개인정보 처리방침</Link>
                        <Link to="#">청소년 보호정책</Link>
                        <Link to="#">저작권 보호정책 표시</Link>
                    </div>
                    <p className="copyright">ⓒ Copyright © 2021 ITL. All rights reserved.</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;