import React, {useEffect, useState} from "react";
import "./css/Menubar.scss";
import {MediaSectionType} from "@models/media";
import {useSelector} from 'react-redux'
import {getSectionURL} from "../common/js/getSectionURL";
import {RootState} from "@app/store";
import {Tabs, Tab} from "@mui/material";
import {useHistory} from "react-router";

function Menubar() {
    const history = useHistory();
    const section = useSelector((state: RootState) => state.section);
    const [value, setValue] = React.useState(0);
    const [mediaSectionList, setMediaSectionList] = useState<MediaSectionType[]>([]);

    useEffect(() => {
        const unlisten = history.listen(() => {
            if (window.location.pathname === "/") setValue(0);
        });
        return () => unlisten()
    }, []);

    useEffect(() => {
        let filter = section.filter((item: MediaSectionType) => item.child.length > 0); // 하위 카테고리가 없으면 그려주지 않음
        setMediaSectionList(filter);
    }, [section]);


    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
    };

    return (
        <div id="Menubar">
            <Tabs
                value={value}
                onChange={handleChange}
                scrollButtons={false}
                variant="scrollable"
                aria-label="scrollable auto tabs"
            >
                {/*<Tab label="회사소개" onClick={() => history.push("/")} />*/}
                <Tab label="Home" onClick={() => history.push("/")} />
                {
                    mediaSectionList.map(item => <Tab key={item.idx} label={item.name} onClick={() => history.push(getSectionURL(item.idx, item.child[0].idx))}/>)
                }
                {/*<Tab icon={<div className="tool"><button className="icon_search ir_txt">검색</button><button className="icon_menu ir_txt">목록 메뉴</button></div>} aria-label="icons" />*/}
            </Tabs>
        </div>
    )
}

export default Menubar;