import React, {useEffect, useState} from "react";
import "../css/AdminSectionSetting.scss";
import { List, arrayMove } from 'react-movable';
import message from "@const/message";
import {useSelector} from "react-redux";
import {RootState} from "@app/store";
import AdminAPI from "@api/admin";
import {MediaSectionType} from "@models/media";
import {ArticleType, ArticleTypeStr} from "@models/article";
import {convertArticleTypeStr} from "../../common/js/convertArticleTypeStr";
import AdminSectionSettingPopup from "./AdminSectionSettingPopup";
import {funcDelete, inputStyles, selectStyles, tdStyles} from "./common/setting";
import _ from "lodash";

interface Props {
    title: string
}
function AdminSectionSetting(props: Props) {
    const maxCount = 6;
    const initSection = useSelector((state: RootState) => state.section);
    const [widths, setWidths] = React.useState<string[]>([]);
    const [items, setItems] = React.useState<MediaSectionType[]>([]);
    const [editSection, setEditSection] = useState(new Array(maxCount).fill(null));
    const [editPopupShow, setEditPopupShow] = useState(false);
    const [targetSection, setTargetSection] = useState({} as MediaSectionType);
    const hasNewData = items.filter(item => !item.idx).length > 0;
    const typeSelectHtml = Object.keys(ArticleTypeStr).map((item, idx) => {
        return <option key={idx} value={ArticleType[item as keyof typeof ArticleType]}>{ArticleTypeStr[item as keyof typeof ArticleTypeStr]}</option>
    })

    useEffect(() => {
        setItems(initSection);
    }, [initSection]);

    const onClickAddField = () => {
        let copy = _.cloneDeep(items);
        if (copy.length >= maxCount) {
            alert(message.alertMaxAdd(maxCount));
            return;
        }
        let editSectionCopy = editSection.concat();
        let defaultType = Object.keys(ArticleType)[0];

        editSectionCopy[copy.length] = { name: '', type: defaultType };
        setEditSection(editSectionCopy);
        setItems(copy.concat({} as MediaSectionType));
    }

    const onChangeSectionType = (type: number, index: number | undefined) => {
        let copy = _.cloneDeep(editSection);
        copy[index || 0].type = type;
        setEditSection(copy);
    }

    const onChangeSectionName = (name: string, index: number | undefined) => {
        let copy = _.cloneDeep(editSection);
        copy[index || 0].name = name;
        setEditSection(copy);
    }

    const onClickAdd = async () => {
        let copy = _.cloneDeep(items);

        for (let i = 0; i < editSection.length; i++) {
            let item = editSection[i];
            if (!item) continue;
            await AdminAPI.addSection(item.type, item.name).then((res: MediaSectionType) => copy[i] = res);
        }
        setItems(copy);
        alert(message.successSave);
    }

    const onChangeDrag = (oldIndex: number, newIndex: number) => {
        let changeItems = _.cloneDeep(arrayMove(items, oldIndex, newIndex));
        let item = changeItems[newIndex];
        item.order = (newIndex + 1);

        AdminAPI.updateSection(item.idx, item.name, item.order).then(() => {
            setItems(arrayMove(items, oldIndex, newIndex))
        });
    }

    const onClickEditPopup = (section_idx: number) => {
        setTargetSection(items.find(item => item.idx === section_idx) || {} as MediaSectionType);
        setEditPopupShow(true);
    }

    return (
        <div id="AdminSectionManage">
            <h2 className="page_title">{props.title}</h2>
            <button className="btn_add" onClick={onClickAddField}>
                <i className="icon_posting"/>
                <span>섹션 추가하기</span>
            </button>
            <List
                beforeDrag={({ elements, index }) => {
                    const cells = Array.from(elements[index].children);
                    const widths = cells.map(cell => window.getComputedStyle(cell).width);
                    setWidths(widths);
                }}
                values={items}
                onChange={({ oldIndex, newIndex }) => onChangeDrag(oldIndex, newIndex)}
                renderList={({ children, props, isDragged }) => (
                    <table>
                        <thead>
                        <tr>
                            <th className="order">게시순서</th>
                            <th className="section_type">섹션타입</th>
                            <th className="section_name">섹션명</th>
                            <th className="manage">관리</th>
                        </tr>
                        </thead>
                        <tbody {...props}>{children}</tbody>
                    </table>
                )}
                renderItem={({ value, index, props, isDragged, isSelected }) => {
                    const _widths = isDragged ? widths : [];
                    const _btnShow = isDragged ? { opacity: 0 } : { opacity: 1 };
                    const isNew = !Boolean(value.idx);
                    const _newDisplay = isNew ? {display: "none"} : {}
                    const row = (
                        <tr
                            {...props}
                            style={{
                                ...props.style,
                                cursor: isDragged ? 'grabbing' : 'inherit',
                                backgroundColor: isDragged || isSelected ? '#EEE' : '#fafafa'
                            }}
                        >
                            <td className="order" style={tdStyles(_widths[0])}>{(index || 0) + 1}</td>
                            <td className="section_type">
                                {
                                    isNew
                                        ? <select style={selectStyles} onChange={e => onChangeSectionType(Number(e.target.value), index)}>{typeSelectHtml}</select>
                                        : convertArticleTypeStr(value.type)
                                }
                            </td>
                            <td className="section_name" style={tdStyles(_widths[1], true)}>
                                {
                                    isNew ? <input type="text" style={inputStyles} onChange={e => onChangeSectionName(e.target.value, index)}/> : value.name
                                }
                            </td>
                            <td className="manage" style={{...tdStyles(_widths[2]), ..._btnShow}}>
                                <div className="btns">
                                    <button className="btn_move" style={_newDisplay} data-movable-handle>이동</button>
                                    <button className="btn_edit" style={_newDisplay} onClick={() => onClickEditPopup(value.idx)}>수정</button>
                                    <button className="btn_delete" onClick={() => funcDelete(value.idx, index, items, setItems)}>삭제</button>
                                </div>
                            </td>
                        </tr>
                    );
                    return isDragged ? (
                        <table style={{ ...props.style, borderSpacing: 0 }}>
                            <tbody>{row}</tbody>
                        </table>
                    ) : row;
                }}
            />
            {
                hasNewData && <button className="btn_save" onClick={onClickAdd}>저장</button>
            }
            {
                editPopupShow && <AdminSectionSettingPopup targetSection={targetSection} section={items} setSection={setItems} onClose={() => setEditPopupShow(false)}/>
            }
        </div>
    )
}

export default AdminSectionSetting;