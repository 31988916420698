import React, {useEffect, useRef, useState} from "react";
import "./css/ReporterArticleAdd.scss";
import MemberAPI from "@api/member";
import AdminAPI from "@api/admin";
import ArticleAPI from "@api/article";
import {Article, ImageItem, Temporary} from "@models/article";
import message from "@const/message";
import {useHistory} from "react-router";
import {EnumPopupType, PopupType} from "./interface/PopupType";
import {MediaInfoType, MemberType} from "@models/member";
import ArticleContainer from "../common/ArticleContainer";
import {checkArticle, setCommonFormData} from "../common/js/funcArticleAdd";

interface Props extends PopupType {
    title?: string
}

function ReporterArticleAdd({title, idx, type}: Props) {
    const history = useHistory();
    const [article, setArticle] = useState<Article>({section_list: [{large_section_idx: 0, medium_section_idx: 0}]} as Article);
    const [uploadImageList, setUploadImageList] = useState<ImageItem[]>([]); // 서버에 저장된 이미지 목록
    const [memberMediaInfo, setMemberMediaInfo] = useState(MediaInfoType.normalUser);
    const titleRef = useRef(null);
    const isEdit = type === EnumPopupType.Edit;
    const isView = type === EnumPopupType.View;
    const PostAPI = memberMediaInfo === MediaInfoType.reporter ? ArticleAPI : AdminAPI;

    useEffect(() => {
        getMediaRank();
    }, []);

    useEffect(() => {
        getArticle();
    }, [idx, memberMediaInfo]);

    const initData = (data: Article) => {
        data.image_list?.forEach((item: ImageItem) => item.file_key = window.$Global.getImageCDN(item.file_key));
        data.thumbnail_key = window.$Global.getImageCDN(data.thumbnail_key);
        setUploadImageList(data.image_list);
        setArticle(data);
    }

    const getMediaRank = () => {
        MemberAPI.myInfo().then((res: MemberType) => setMemberMediaInfo(res.media_info.rank));
    };

    const getArticle = () => {
        if (idx && memberMediaInfo) {
            PostAPI.getArticle(idx).then((res: Article) => initData(res));
        }
    }

    const addArticle = async (temporary: Temporary.Temporary | Temporary.Registration) => {
        if (checkArticle(article, [titleRef])) {
            let formData = new FormData();
            await setCommonFormData(formData, article, uploadImageList);
            formData.append("temporary", temporary.toString());
            await PostAPI.addArticle(formData).then(() => {
                alert(temporary === Temporary.Temporary ? message.successSave : message.approvalRequest);
                history.go(0);
            });
        }
    }

    const updateArticle = async () => {
        if (checkArticle(article, [titleRef])) {
            if (idx) {
                let formData = new FormData();
                formData.append("temporary", Temporary.Registration.toString());
                await setCommonFormData(formData, article, uploadImageList);
                await PostAPI.updateArticle(idx, formData).then(() => {
                    alert(message.successSave);
                    history.go(0);
                });
            }
        }
    };

    const fieldObj = [
        {
            title: "기사 링크",
            type: "text",
            className: "full_size",
            placeholder: false,
            key: "link_url" as keyof Article,
            ref: null
        },
        {
            title: "제목",
            type: "text",
            className: "full_size",
            placeholder: true,
            key: "title" as keyof Article,
            ref: titleRef
        },
        {
            title: "부제목",
            type: "text",
            className: "full_size",
            placeholder: true,
            key: "sub_title" as keyof Article,
            ref: null
        },
        {
            title: "등록일자",
            type: "date",
            placeholder: false,
            key: "inserted_at" as keyof Article,
            ref: null
        },

    ];

    const BtnComponents =
        <div className="btns">
        {
            !isView &&
            (
                isEdit
                ? <button className="btn_request" onClick={() => updateArticle()}>저장하기</button>
                :
                <>
                    {
                        memberMediaInfo !== MediaInfoType.mediaMaster && <button className="btn_save" onClick={() => addArticle(Temporary.Temporary)}>임시 저장</button>
                    }
                    <button className="btn_request" onClick={() => addArticle(Temporary.Registration)}>승인 요청</button>
                </>
            )
        }
        </div>

    return (
        <div id="ReporterArticleAdd">
            <ArticleContainer title={title} isView={isView} article={article} setArticle={setArticle} fieldObj={fieldObj} BtnComponents={BtnComponents}/>
        </div>
    )
}

export default ReporterArticleAdd;