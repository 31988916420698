import { State } from "@models/taskRequest";
export function convertTaskRequestStateStr(state: number) {
    switch (state) {
        case State.Wait:
            return State.WaitStr;
        case State.Success:
            return State.SuccessStr;
        case State.Reject:
            return State.RejectStr;
    }
}