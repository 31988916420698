import React, {ChangeEvent, useCallback, useEffect, useState} from "react";
import "./css/ArticleForm.scss";
import {Article, ImageItem} from "@models/article";
import {JoinMediaItemType, MediaSectionType} from "@models/media";
import MediaAPI from "@api/media";
import message from "@const/message";
import variable from "@const/variable";
import _ from "lodash";
import {ArticleFormType} from "../common/interface/ArticleAddType";
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; // import styles
import 'react-summernote/lang/summernote-ko-KR'; // you can import any other locale
import 'bootstrap/js/modal';
import 'bootstrap/js/dropdown';
import 'bootstrap/js/tooltip';
import {SummernoteType} from "./interface/Summernote";

function ArticleForm(props: ArticleFormType) {
    const [summerNote, setSummerNote] = useState<SummernoteType>({} as SummernoteType);
    const [content, setContent] = useState('');
    const [mediaList, setMediaList] = useState<JoinMediaItemType[]>([]);
    const [mediaSectionList, setMediaSectionList] = useState<MediaSectionType[][]>([]);

    useEffect(() => {
        getJoinMediaList();
    }, []);

    useEffect(() => {
        let html = content;
        let src = html.match(new RegExp(variable.dataUriRegx + '|' + variable.imageCDNRegx, 'g'));
        let result = src?.map(file_key => {return {file_key}})  || [];
        props.setArticle({...props.article, content: html, image_list: result as ImageItem[]});
    }, [content]);

    useEffect(() => {
        if (props.article.idx) {
            if (summerNote?.summernote && props.article.content) {
                summerNote?.summernote('code', props.article.content);
            }
            let selectMediaList = props.article.section_list.map(item => mediaList.find(item2 => item.media_idx === item2.media_idx)) || [[], []];
            let childSection = selectMediaList.map(item => item?.section);
            setMediaSectionList(childSection as MediaSectionType[][]);
        }
    }, [props.article.idx]);

    useEffect(() => {
        if (summerNote?.summernote) summerNote?.summernote('focus');
    }, [summerNote]);

    useEffect(() => {
        let noneSelectImage = props.article.image_list?.findIndex(item => item.file_key === props.article.thumbnail_key) === -1;
        if (props.article.image_list?.length === 1 && noneSelectImage) { // 선택한 썸네일 이미지가 삭제되었거나 썸네일 인덱스가 이미지 리스트 개수보다 클 경우 0으로 초기화
            let copy = _.cloneDeep(props.article);
            copy.thumbnail_key = copy.image_list[0].file_key;
            props.setArticle(copy);
        }
    }, [props.article.image_list]);

    const getJoinMediaList = () => {
        MediaAPI.getJoinMediaList().then((res: JoinMediaItemType[]) => setMediaList(res));
    }

    const onChange = useCallback((html: string) => {
        setContent(html);
    }, [])

    const onImageUpload = async (images: FileList, insertImage: any) => {
        for (let i = 0; i < images.length; i++) {
            let image = images[i];
            const reader = new FileReader();
            reader.onloadend = () => {
                let extend = image.type.substr(image.type.lastIndexOf("/") + 1);
                if (variable.imgTypeRegx.test(extend.toString())) {
                    insertImage(reader.result);
                } else {
                    alert(message.onlyImageFile);
                }
            }
            reader.readAsDataURL(images[i]);
        }
    }

    const onChangeMedia = (e: ChangeEvent<HTMLSelectElement>, index: number) => {
        let findMediaList = mediaList.find(item => item.media_idx === Number(e.target.value));
        let media_idx =  findMediaList?.media_idx;
        let section_list = findMediaList?.section;

        if (media_idx) {
            let copy = _.cloneDeep(props.article);
            copy.section_list[index].media_idx = media_idx;
            copy.section_list[index].large_section_idx = 0;
            copy.section_list[index].medium_section_idx = 0;
            props.setArticle(copy);
        }

        if (section_list) {
            let copy = _.cloneDeep(mediaSectionList);
            copy[index] = section_list;
            setMediaSectionList(copy);
        }
    }

    const addSectionList = () => {
        let copy = _.cloneDeep(props.article);
        copy.section_list.push({large_section_idx:0, medium_section_idx: 0});
        props.setArticle(copy);
    }

    const deleteSectionList = (index: number) => {
        let copy = _.cloneDeep(props.article);
        copy.section_list.splice(index,  1);
        props.setArticle(copy);
    }

    return (
        <div id="ArticleForm">
            <div className="form">
                <h2 className="title">{props.title}</h2>
                {
                    props.article.section_list?.map((item, idx) => {
                        const localMediaSectionList = mediaSectionList[idx] || [];
                        const mediaSectionChildList = localMediaSectionList.find(item2 => item2.idx === item.large_section_idx)?.child;
                        const childDisabled = props.isView || !Boolean(item.large_section_idx) || !Boolean(mediaSectionChildList?.length);

                        return (
                            <>
                                <div className="field">
                                    <p className="field_title">미디어 선택</p>
                                    <div className="wrap_media_select">
                                        <select className="full_size media_select_box"
                                                value={item.media_idx}
                                                disabled={props.isView || props.largeSelectDisabled}
                                                onChange={e => onChangeMedia(e, idx)}>
                                            <option hidden>선택</option>
                                            {
                                                mediaList.map(item => <option key={item.domain} value={item.media_idx}>{item.domain}</option>)
                                            }
                                        </select>
                                        {
                                            !(props.isView || props.largeSelectDisabled)
                                            &&
                                            (
                                                idx === 0
                                                ? <i className="icon_add" onClick={addSectionList}/>
                                                : <i className="icon_del" onClick={() => deleteSectionList(idx)}/>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="field">
                                    <p className="field_title">섹션</p>
                                    <div key={item.large_section_idx} className="wrap_select">
                                        <select value={item.large_section_idx}
                                                disabled={props.isView || props.largeSelectDisabled || localMediaSectionList.length === 0}
                                                onChange={e => {
                                                    let copy = _.cloneDeep(props.article);
                                                    copy.section_list[idx].large_section_idx = Number(e.target.value);
                                                    props.setArticle(copy);
                                                }}>
                                            <option hidden>선택</option>
                                            {
                                                localMediaSectionList.map((item, idx) => <option key={idx} value={item.idx}>{item.name}</option>)
                                            }
                                        </select>
                                        <select value={item.medium_section_idx}
                                                disabled={childDisabled}
                                                onChange={e => {
                                                    let copy = _.cloneDeep(props.article);
                                                    copy.section_list[idx].medium_section_idx = Number(e.target.value);
                                                    props.setArticle(copy);
                                                }}>
                                            <option hidden>선택</option>
                                            {
                                                mediaSectionChildList?.map((item, idx) => <option key={idx} value={item.idx}>{item.name}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>
                            </>
                        )
                    })
                }
                {
                    props.fieldObj.map((item, idx) => {
                        let value = props.article[item.key as keyof Article]?.toString();
                        if (item.type === "date") value = window.$Global.convertDate(Number(value), "", "-");
                        const onChange = (v: string) => {
                            let changeValue:string | number = v;
                            if (item.type === "date") {
                                changeValue = new Date(changeValue).getTime();
                            }
                            props.setArticle({...props.article, [item.key]: changeValue});
                        }
                        return (
                            <div key={idx} className="field">
                                <p className="field_title">{item.title}</p>
                                <input type={item.type} readOnly={props.isView} ref={item.ref} data-name={item.title}
                                       className={`input ${item.className}`} placeholder={(item.placeholder && !props.isView) ? message.requireInput : ""}
                                       value={value} onChange={e => onChange(e.target.value)}
                                />
                            </div>
                        )
                    })
                }
                {
                    props.isView
                    ?
                    <div className="summernote">
                        <div dangerouslySetInnerHTML={ {__html: props.article.content} }/>
                    </div>
                    :
                    <div className="field summernote">
                        <ReactSummernote
                            options={{
                                lang: 'ko-KR',
                                height: 350,
                                dialogsInBody: true,
                                toolbar: [
                                    ['style', ['style']],
                                    ['font', ['fontsize', 'color', 'bold', 'underline', 'strikethrough', 'clear']],
                                    ['para', ['ul', 'ol', 'paragraph', 'height']],
                                    ['table', ['table']],
                                    ['insert', ['link', 'picture', 'video']],
                                    ['view', ['fullscreen']]
                                ]
                            }}
                            onInit={(e: any) => setSummerNote(e)}
                            onChange={onChange}
                            onImageUpload={onImageUpload}
                        />
                    </div>
                }
                <div className="btns">
                    { !props.isView && props.BtnComponents }
                </div>
            </div>
        </div>
    )
}

export default ArticleForm;