import React, {useEffect, useState} from "react";
import "../css/AdminTaskRequestDetail.scss";
import AdminAPI from "@api/admin";
import {TaskRequestType} from "@models/taskRequest";
import {convertTaskRequestStateStr} from "../../common/js/convertTaskRequestStateStr";

interface Props {
    idx: number
    funcBack: () => void
}

function AdminTaskRequestDetail({idx, funcBack}: Props) {
    const [request, setRequest] = useState({} as TaskRequestType);
    useEffect(() => {
        AdminAPI.getRequestInfo(idx).then((res: TaskRequestType) => setRequest(res));
    }, [idx]);

    return (
        <div id="AdminTaskRequestDetail">
            <div className="board">
                <h2 className="title">{request.title}</h2>
                <div className="date">
                    <i className="icon_upload_time"/>
                    <p>작성일자: {window.$Global.convertDate(request.created_at)}</p>
                </div>
                <div className="content toastui-editor-contents" dangerouslySetInnerHTML={ {__html: request.content} }/>
            </div>

            <div className="comment_area">
                <div className="inner">
                    <img className="admin_profile" src={require('../../../assets/image/common/ic-user-profile.svg').default} alt="관리자 프로필 이미지"/>
                    <p className="state">{convertTaskRequestStateStr(request.state)}</p>
                </div>
                <div className="comment toastui-editor-contents" dangerouslySetInnerHTML={ {__html: request.comment} }/>
            </div>

            <button className="btn_list" onClick={funcBack}>
                <i className="icon_menu"/>
                <span>목록으로 돌아가기</span>
            </button>
        </div>
    )
}

export default AdminTaskRequestDetail;