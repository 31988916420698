import React, {useRef} from "react";
import {Article} from "@models/article";
import "./css/EditorLibrary.scss";
import _ from "lodash";
import variable from "@const/variable";
import message from "@const/message";

interface Props {
    isView?: boolean
    article: Article
    setArticle: (article: Article) => void
}

function EditorLibrary(props: Props) {
    const maxSize = '200MB';
    const imageRef = useRef<HTMLInputElement>(null);
    const fileRef = useRef<HTMLInputElement>(null);

    const setSelectThumbnail = (file_key: string) => {
        if (!props.isView) {
            let copy = _.cloneDeep(props.article);
            copy.thumbnail_key = file_key;
            props.setArticle(copy);
        }
    }

    const onChangeImages = (images: FileList | null) => {
        if (images?.length) {
            let imageList = props.article.image_list || [];
            let arr: any = Array.from(images);
            let result = arr.map((item: any) => {
                item.idx = 0;

                const reader = new FileReader();
                reader.onloadend = () => {
                    if (item?.type) {
                        let extend = item.type.substr(item.type.lastIndexOf("/") + 1);
                        if (variable.imgTypeRegx.test(extend.toString())) {
                            item.dataUri = reader.result?.toString() || "";
                            item.file_key = item.dataUri;
                        } else {
                            alert(message.onlyImageFile);
                        }
                    }
                }
                reader.readAsDataURL(item);
                return item;
            });
            console.log(result);
            props.setArticle({...props.article, image_list: imageList.concat(result)});
        }
    };

    const onChangeFiles = (files: FileList | null) => {
        if (files?.length) {
            let fileList = props.article.file_list || [];
            props.setArticle({...props.article, file_list: fileList.concat(Array.from(files))});
        }
    };

    const deleteFile = (file_idx: number, index: number) => {
        let copy = _.cloneDeep(props.article);
        let delete_file_list = props.article.delete_file_list || [];
        if (file_idx) {
            copy.delete_file_list = delete_file_list.concat({idx: file_idx});
        }
        copy.file_list.splice(index,1);
        props.setArticle(copy);
    };

    return (
        <div id="EditorLibrary" className="library">
            <h2 className="title">라이브러리</h2>
            <div className="lib_image_area">
                <div className="head">
                    <h2 className="lib_image_title">이미지</h2>
                    {/*{*/}
                    {/*    !props.isView && <button className="btn_add" onClick={() => imageRef.current?.click()}>등 록</button>*/}
                    {/*}*/}
                    <input type="file" ref={imageRef} onChange={e => onChangeImages(e.target.files)} multiple hidden/>
                </div>
                <ul className="lib_image_list">
                {
                    props.article.image_list?.map((item, idx) => {
                        return (
                            <li key={idx} className="lib_image_item" onClick={() => setSelectThumbnail(item.file_key)}>
                                <p>{item.dataUri}</p>
                                <img src={item.file_key} alt="썸네일 후보 이미지" />
                                {
                                    props.article.thumbnail_key === item.file_key
                                    &&
                                    <div className="thumbnail">
                                        <p>썸네일</p>
                                    </div>
                                }
                            </li>
                        )
                    })
                }
                </ul>
            </div>
            <div className="lib_file_area">
                <div className="head">
                    <div className="inner">
                        <h2 className="lib_file_title">첨부파일</h2>
                        <p>최대 크기 {maxSize}</p>
                    </div>
                    {
                        !props.isView && <button className="btn_add" onClick={() => fileRef.current?.click()}>등 록</button>
                    }
                    <input type="file" ref={fileRef} onChange={e => onChangeFiles(e.target.files)} multiple hidden/>
                </div>
                <ul className="lib_file_list">
                {
                    props.article.file_list?.map((item, idx) => {
                        return (
                            <li key={idx} className="lib_file_item">
                                <div className="info">
                                    <p className="filename">{item.name || item.file_name}</p>
                                    <p className="size">{window.$Global.convertFileSize(item.size || item.file_size)}</p>
                                </div>
                                {
                                    !props.isView && <button className="icon_exit ir_txt" onClick={() => deleteFile(item.idx || 0, idx)}>삭제</button>
                                }
                            </li>
                        )
                    })
                }
                </ul>
            </div>
        </div>
    )
}

export default EditorLibrary;