import React, {useState} from "react";
import {useHistory, useLocation} from "react-router";
import qs from "query-string";

// 리스트에 기본적인 페이징을 위한 변수 및 함수 정의
const withList = (WrappedComponent: (p: any) => React.ReactElement) => {
    const Component = (props: any) => {
        const history = useHistory();
        const location = useLocation();
        const search = qs.parse(location.search);
        const keyword = search.keyword?.toString() || '';
        const option = Number(search.option || 0);
        const page = Number(search.page?.toString() || 1);
        const count = 15;
        const [searchType, setSearchType] = useState(option); // 0: 제목 1: 내용
        const [list, setList] = useState({});
        const commonQuery = window.$Global.getURLQueryKeep();

        const changePage = (page:number) => {
            history.push(`${commonQuery}option=${searchType}&keyword=${keyword}&page=${page}`);
            window.scrollTo(0, 0);
        }

        const searchList = (keyword: string) => {
            history.push(`${commonQuery}option=${searchType}&keyword=${keyword}&page=${1}`);
        };

        const combinedProps = {
            ...props,
            list,
            page,
            count,
            keyword,
            setList,
            searchType,
            setSearchType,
            searchList,
            changePage
        };

        return <WrappedComponent {...combinedProps} />;
    };
    return Component;
};

export default withList;
