import React, {useCallback, useRef, useState} from "react";
import "../css/AdminTaskRequestAdd.scss";
import message from "@const/message";
import AdminAPI from "@api/admin";
import validator from "../../../util/validator";
import {useHistory} from "react-router";
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; // import styles
import 'react-summernote/lang/summernote-ko-KR'; // you can import any other locale
import 'bootstrap/js/modal';
import 'bootstrap/js/dropdown';
import 'bootstrap/js/tooltip';
import {SummernoteType} from "../../common/interface/Summernote";

interface Props {
    title: string
}

function AdminTaskRequestAdd(props: Props) {
    const history = useHistory();
    const titleRef = useRef<HTMLInputElement>(null);
    const [summerNote, setSummerNote] = useState<SummernoteType>({} as SummernoteType);
    const [content, setContent] = useState('');
    const isNotContent = "<p><br></p>";

    const onAdd = () => {
        if (validator.CheckRefValue([titleRef])) {
            if (!content || content === isNotContent) {
                alert(message.requireContent);
                return;
            }

            if (titleRef.current) {
                let payload = {
                    title: titleRef.current.value,
                    content: content
                }

                AdminAPI.addRequest(payload).then(() => {
                    alert(message.successADD);
                    history.go(0);
                });
            }
        }
    }

    const onChange = useCallback((html: string) => {
        setContent(html);
    }, []);

    return (
        <div id="AdminTaskRequestAdd">
            <h2 className="page_title">{props.title}</h2>

            <div className="content">
                <div className="col">
                    <div className="row">
                        <label className="row_title" htmlFor="taskTitle">제목</label>
                        <input type="text" id="taskTitle" ref={titleRef} data-name={"제목"} placeholder={message.requireInput}/>
                    </div>
                </div>
                <div className="field summernote">
                    <ReactSummernote
                        options={{
                            lang: 'ko-KR',
                            height: 350,
                            dialogsInBody: true,
                            toolbar: [
                                ['style', ['style']],
                                ['font', ['fontsize', 'color', 'bold', 'underline', 'strikethrough', 'clear']],
                                ['para', ['ul', 'ol', 'paragraph', 'height']],
                                ['table', ['table']],
                                ['insert', ['link', 'video']],
                                ['view', ['fullscreen']]
                            ]
                        }}
                        onInit={(e: any) => setSummerNote(e)}
                        onChange={onChange}
                    />
                </div>
            </div>

            <button className="btn_add" onClick={onAdd}>등록</button>
        </div>
    )
}

export default AdminTaskRequestAdd;