import React, {useEffect, useRef, useState} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import "./css/Home.scss";
import {Link} from "react-router-dom";
import ArticleListImageAndText from "../common/ArticleListImageAndText";
import MediaAPI from "@api/media";
import {HomeType} from "@models/home";
import {useSelector} from "react-redux";
import {RootState} from "@app/store";
import ArticleAreaItem from "./ArticleAreaItem";
import AnnouncementBoard from "../announcement/AnnouncementBoard";
import SideArticleListCategory from "../common/SideArticleListCategory";
import {getSectionURL} from "../common/js/getSectionURL";
import {getArticleURL} from "../common/js/getArticleURL";

function Home() {
    const sliderRef = useRef<Slider>(null);
    const [sliderPlay, setSliderPlay] = useState(true);
    const [homeData, setHomeData] = useState<HomeType>({} as HomeType);
    const section = useSelector((state: RootState) => state.section);
    const sectionE = homeData.section?.e;
    const sectionS = homeData.section?.s;
    const sectionG = homeData.section?.g;
    const sectionESG = homeData.section?.esg.filter(item => item.thumbnail_key);
    const sectionETC = homeData.section?.etc;
    const announcement= homeData.section?.announcement;
    const sectionEURL = getSectionURL(sectionE?.idx, section.find(item => item.idx === sectionE?.idx)?.child[0]?.idx);
    const sectionSURL = getSectionURL(sectionS?.idx, section.find(item => item.idx === sectionS?.idx)?.child[0]?.idx);
    const sectionGURL = getSectionURL(sectionG?.idx, section.find(item => item.idx === sectionG?.idx)?.child[0]?.idx);

    const slider = {
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        autoplay: true,
        touchMove: false,
        autoplaySpeed: 2500,
        responsive: [
            {
                breakpoint: 758,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            }
        ]
    };

    useEffect(() => {
        MediaAPI.getHome().then((res: HomeType) => setHomeData(res));
    }, []);

    const slickPause = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPause();
        }
        setSliderPlay(false);
    }

    const slickPlay = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPlay();
        }
        setSliderPlay(true);
    }

    const prevSlider = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    }

    const nextSlider = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    }

    return (
        <div id="Home">
            <div className="wrap wrap_article_side">
                <ArticleAreaItem title={"신규 등록"} url={sectionEURL} list={sectionESG}/>
                <ArticleListImageAndText data={homeData.popular}/>
            </div>

            <div className="slide_area">
                <div className="slide_head">
                    <h2 className="title">{sectionE?.name}</h2>
                    <div className="set_area">
                        <button className="btn_more_left ir_txt" onClick={prevSlider}>이전</button>
                        <button className="btn_more_right ir_txt" onClick={nextSlider}>다음</button>
                        {
                            sliderPlay
                            ? <button className="btn_pause ir_txt" onClick={slickPause}>정지</button>
                            : <button className="btn_start ir_txt" onClick={slickPlay}>실행</button>
                        }
                    </div>
                </div>
                <div className="slider">
                    <Slider {...slider} ref={sliderRef}>
                        {
                            sectionE?.article?.map(item => {
                                return (
                                    <Link key={item.idx} to={getArticleURL(item.idx,  item.section_idx)}>
                                        <img src={window.$Global.getImageCDN(item.thumbnail_key)} alt="신규 기사 이미지"/>
                                    </Link>
                                )
                            })
                        }
                    </Slider>
                </div>
            </div>
            <div className="wrap wrap_article">
                <ArticleAreaItem title={sectionS?.name} url={sectionSURL} list={sectionS?.article}/>
                <ArticleAreaItem title={sectionG?.name} url={sectionGURL} list={sectionG?.article}/>
            </div>
            <div className="wrap has_side">
                <AnnouncementBoard list={announcement}/>
                <SideArticleListCategory list={sectionETC}/>
            </div>
        </div>
    )
}

export default Home;