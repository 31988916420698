import React, {useEffect, useState} from "react";
import "./css/DashBoardTab.scss";
import {useHistory} from "react-router";
import {Tab} from "../../page/mypage/interface/Tab";

export interface Props {
    tab: Tab
    activeDepth1: number
    activeDepth2: number
}

function DashBoardTab({tab, activeDepth1, activeDepth2}: Props) {
    const history = useHistory();
    const [depth1, setDepth1] = useState(activeDepth1);
    const [depth2, setDepth2] = useState(activeDepth2);
    const commonQuery = window.$Global.getURLQueryKeep(true, ['depth1', 'depth2']);

    useEffect(() => {
        history.push(`${commonQuery}depth1=${depth1}&depth2=${depth2}`);
    }, [depth1, depth2]);

    const onchangeDepth1 = (idx: number) => {
        setDepth1(idx)
        setDepth2(0);
    }

    return (
        <div id="DashBoardTab">
            <div className="dash_title">{tab.title}</div>
            <ul className="tab_list">
            {
                tab.list.map((item, idx) => {
                    return (
                        <li key={idx} className={`tab_item ${depth1 === idx ? 'active' : ''}`}>
                            <h2 className="tab_title" onClick={() => onchangeDepth1(idx)}>{item.title}</h2>
                            <ul className="child_tab_list">
                            {
                                item.child.map((item2, idx2) => <li key={idx2} className={`child_tab_item ${depth2 === idx2 ? 'active' : ''}`} onClick={() => setDepth2(idx2)}>{item2}</li>)
                            }
                            </ul>
                        </li>
                    )
                })
            }
            </ul>
        </div>
    )
}

export default DashBoardTab;