import React, {useEffect, useState} from "react";
import "./css/JoinMediaList.scss";
import MediaAPI from "@api/media";
import {JoinMediaItemType} from "@models/media";

interface Props {
    title: string
}

function JoinMediaList(props: Props) {
    const [mediaList, setMediaList] = useState<JoinMediaItemType[]>([]);

    useEffect(() => {
        MediaAPI.getJoinMediaList().then((res: JoinMediaItemType[]) => {
            setMediaList(res);
        });
    }, []);

    return (
        <div id="JoinMediaList">
            <div className="page_title">{props.title}</div>
            <div className="media_list">
                {
                    mediaList.filter((item: JoinMediaItemType) => item.media_logo_key).map((item: JoinMediaItemType) => {
                        return (
                            <div className="media_item" onClick={() => window.open(`https://${item.domain}`, '_blank')}>
                                <img src={window.$Global.getImageCDN(item.media_logo_key)} alt="미디어 로고 이미지" />
                                <p>{item.domain}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default JoinMediaList;