import React, {useEffect, useState} from "react";
import "./css/Header.scss";
import {Link} from "react-router-dom";
import MemberAPI from "@api/member";
import {MemberType} from "@models/member";
import {useDispatch, useSelector} from "react-redux";
import {setMember} from "@features/member/memberSlice";
import {RootState} from "@app/store";

function Header() {
    const dispatch = useDispatch();
    const mediaInfo = useSelector((state: RootState) => state.mediaInfo);
    const [userName, setUserName] = useState("");
    const [headerStyle, setHeaderStyle] = useState({});
    const isLogin = sessionStorage.getItem("token");

    useEffect(() => {
        if (isLogin) {
            MemberAPI.myInfo().then((res: MemberType) => {
                setUserName(res.name);
                dispatch(setMember(res))
            });
        }
    }, []);

    useEffect(() => {
        setHeaderStyle(window.$Global.sizeCal(window.$Global.getImageCDN(mediaInfo.media_logo_key), 67));
    }, [mediaInfo]);

    const logout = () => {
        sessionStorage.removeItem("token");
    };

    return (
        <header id="Header">
            <Link to="/">
                <img src={window.$Global.getImageCDN(mediaInfo.media_logo_key)} className="logo" style={headerStyle}/>
            </Link>
            <ul className="btn_list">
                {
                    isLogin
                    ?
                    <>
                        <li>
                            <p>{userName}님 안녕하세요?</p>
                        </li>
                        <li>
                            <Link to="/myPage">마이페이지</Link>
                        </li>
                        <li>
                            <Link to="/" href="#" onClick={logout}>로그아웃</Link>
                        </li>
                    </>
                    :
                    <>
                        <li>
                            <Link to="/login" href="#">로그인</Link>
                        </li>
                        <li>
                            <Link to="/signup" href="#">회원가입</Link>
                        </li>
                    </>
                }
            </ul>
        </header>
    )
}

export default Header;