import React from "react";
import "./css/ArticlePopup.scss";

interface PopupProps {
    show: boolean
    setShow: (show:boolean) => void
    Component: (props: any) => React.ReactElement
}

function ArticlePopup({show, setShow, Component}: PopupProps) {
    if (!show) return null;

    const onClickPopupClose = () => setShow(false);

    return (
        <div id="ArticlePopup">
            <div className="popup_head">
                <h2>상세 정보</h2>
                <button className="icon_exit ir_txt" onClick={onClickPopupClose}>팝업 닫기</button>
            </div>
            <Component/>
        </div>
    )
}

export default ArticlePopup;