import React, {useEffect} from "react";
import "../css/AdminTaskRequestList.scss";
import DashBoardListHead from "../../common/DashBoardListHead";
import AdminAPI from "@api/admin";
import qs from "query-string";
import {useLocation} from "react-router";
import Pagination from "../../common/Pagination";
import withList from "../../common/hoc/withList";
import {GetListType} from "../../common/interface/GetListType";
import {GetArticleOptionType, ListType} from "@models/article";
import {convertTaskRequestStateStr} from "../../common/js/convertTaskRequestStateStr";

interface Props extends GetListType<ListType> {
    title: string
    funcDetail: (idx: number) => void
}

function AdminTaskRequestList(props: Props) {
    const location = useLocation();
    const page = Number(qs.parse(location.search).page?.toString() || 1);

    useEffect(() => {
        getList();
    }, [page]);

    const getList = () => {
        let params: GetArticleOptionType = {
            page: page,
            count: props.count,
        };
        if (props.keyword) {
            params.keyword = props.keyword;
            params.option = props.searchType;
        }
        AdminAPI.getRequest(params).then((res: ListType) => props.setList(res));
    };

    return (
        <div id="AdminTaskRequestList">
            <h2 className="page_title">{props.title}</h2>
            <DashBoardListHead count={props.list.total_count} countTxt={"총 업무 요청 수"} initKeyword={props.keyword} searchType={props.searchType}
                               setSearchType={props.setSearchType} searchAPI={props.searchList}
            />
            <table>
                <caption className="ir_txt">업무 요청 목록</caption>
                <colgroup>
                    <col width="10%"/>
                    <col width="70%"/>
                    <col width="10%"/>
                    <col width="10%"/>
                </colgroup>
                <thead>
                    <tr>
                        <th>번호</th>
                        <th>제목</th>
                        <th>진행 상태</th>
                        <th>작성일자</th>
                    </tr>
                </thead>
                <tbody>
                {
                    props.list.items?.map((item, idx) => {
                        return (
                            <tr key={idx} onClick={() => props.funcDetail(item.idx)}>
                                <td>{props.list.total_count - idx - ((props.page - 1) * props.count)}</td>
                                <td>{item.title}</td>
                                <td>{convertTaskRequestStateStr(item.state)}</td>
                                <td>{window.$Global.convertDate(item.created_at)}</td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
            <Pagination curPage={page} lastNum={props.list.last} onClick={props.changePage} />
        </div>
    )
}

export default withList(AdminTaskRequestList);