import React from "react";
import "./css/AnnouncementBoardTable.scss";
import {ItemType} from "@models/article";
import {useHistory} from "react-router";
import {getArticleURL} from "../common/js/getArticleURL";
interface Props {
    list: ItemType[]
}

function AnnouncementBoardTable({list}: Props) {
    const history = useHistory();
    return (
        <table id="AnnouncementBoardTable">
            <colgroup>
                <col width="10%"/>
                <col width="auto"/>
                <col className="pc" width="10%"/>
                <col width="10%"/>
            </colgroup>
            <thead>
                <tr>
                    <th>번호</th>
                    <th>보고서명</th>
                    <th className="pc">작성인</th>
                    <th>입력일자</th>
                </tr>
            </thead>
            <tbody>
            {
                list?.map((item, idx) => {
                    return (
                        <tr key={idx} onClick={() => history.push(getArticleURL(item.idx,  item.section_idx))}>
                            <td>{item.idx}</td>
                            <td>{item.title}</td>
                            <td className="pc">{item.reporter_name}</td>
                            <td>{window.$Global.convertDate(item.inserted_at)}</td>
                        </tr>
                    )
                })
            }
            </tbody>
        </table>
    )
}

export default AnnouncementBoardTable;