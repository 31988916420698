import {Article, ImageItem} from "@models/article";
import variable from "@const/variable";
import ArticleAPI from "@api/article";
import _ from "lodash";
import message from "@const/message";
import validator from "@util/validator";
import {RefObject} from "react";

export const convertContent = async (article: Article, uploadImageList: ImageItem[]) => {
    if (!uploadImage) return;
    let imageCDNList:ImageItem[] = await uploadImage(article) || [];
    let editorHTML = article.content;
    let imageList:ImageItem[] = [];

    if (imageCDNList.length) {
        imageCDNList.forEach((item, idx) => editorHTML = editorHTML.replaceAll(item.dataUri, window.$Global.getImageCDN(item.file_key)));
    }

    if (article.image_list.length) {
        article.image_list.forEach(item => {
            let find;
            let alreadyUpload = item.file_key.indexOf(variable.urlHttps) > -1;
            if (alreadyUpload) { // 이미 서버에 올라간 이미지일 경우
                find = uploadImageList.find(uploadItem => item.file_key === uploadItem.file_key);
            } else { // 아직 서버에 올라가지 않은 이미지일 경우
                find = imageCDNList.find(cdnItem => item.file_key === cdnItem.dataUri);
            }
            imageList.push({thumbnail: false, idx: find?.idx} as ImageItem);
        });
        let thumbnailIndex = article.image_list.findIndex((item:ImageItem) => item.file_key === article.thumbnail_key);
        thumbnailIndex = thumbnailIndex ? thumbnailIndex : 0;
        imageList[thumbnailIndex].thumbnail = true;
    }
    return { content: editorHTML, image_list: imageList };
};

const uploadImage = async (article: Article) => {
    let uploadList = article.image_list.filter(item => item.file_key.indexOf(variable.urlHttps) === -1); // 이미 서버에 저장된 이미지는 제외
    if (uploadList.length) {
        const formData = new FormData();

        uploadList.forEach(item => {
            let blob = window.$Global.dataURItoBlob(item.file_key);
            formData.append('image_file', new File([blob], `file.${blob.type}`))
        });
        return await ArticleAPI.uploadArticleImage(formData).then((res: ImageItem[]) => {
            uploadList.forEach((item, idx) => res[idx].dataUri = item.file_key);
            return res;
        });
    }
};

export const setCommonFormData = async (formData: FormData, article: Article, uploadImageList: ImageItem[]) => {
    let copy = _.cloneDeep(article);
    let result = await convertContent(article, uploadImageList);
    if (result) {
        copy.content = result.content;
        copy.image_list = result.image_list;
    }
    copy.thumbnail_key = "";
    window.$Global.setFormData(formData, copy);
}

export const checkArticle = (article: Article, refs: RefObject<any>[]) => {
    let checkResult = true;
    article.section_list.forEach(item => {
        if (item.medium_section_idx === 0) checkResult = false;
    })

    if (!checkResult) {
        alert(message.choiceSection);
        return false;
    }

    if (validator.CheckRefValue(refs)) return true;
    return false
}