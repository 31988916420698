import React, {useEffect, useState} from "react";
import "../css/AdminArticleMange.scss";
import DashBoardListHead from "../../common/DashBoardListHead";
import AdminAPI from "@api/admin";
import {GetArticleOptionType, ItemType, ListType} from "@models/article";
import {useLocation} from "react-router";
import qs from "query-string";
import Pagination from "../../common/Pagination";
import message from "@const/message";
import {convertArticleStateStr} from "../../common/js/convertArticleStateStr";
import ArticlePopup from "../../common/ArticlePopup";
import {PopupType} from "../../reporter/interface/PopupType";
import withList from "../../common/hoc/withList";
import {GetListType} from "../../common/interface/GetListType";
import ReporterArticleAdd from "../../reporter/ReporterArticleAdd";

interface Props extends GetListType<ListType> {
    title: string
}

function AdminArticleMange(props: Props) {
    const location = useLocation();
    const page = Number(qs.parse(location.search).page?.toString() || 1);

    const [popupShow, setPopupShow] = useState(false);
    const [popupType, setPopupType] = useState<PopupType>({idx: 0, type: "VIEW"});
    const [checkList, setCheckList] = useState<Boolean[]>([]);

    useEffect(() => {
        getList();
    }, [page]);

    const getList = () => {
        let params: GetArticleOptionType = {
            page: page,
            count: props.count,
        };

        if (props.keyword) {
            params.keyword = props.keyword;
            params.option = props.searchType;
        }

        AdminAPI.getArticleList(params).then((res: ListType) => {
            props.setList(res);
            setCheckList(new Array(res.items.length).fill(false));
        });
    };

    const confirmArticle = (article_idx: number, section_idx: number) => {
        if (window.confirm(message.confirm)) {
            AdminAPI.confirmArticle(article_idx, section_idx).then(() => getList());
        }
    }

    const rejectArticle = (article_idx: number, section_idx: number) => {
        if (window.confirm(message.reject)) {
            AdminAPI.rejectArticle(article_idx, section_idx).then(() => getList());
        }
    }

    const onClickPopupShow = (idx: number) => {
        setPopupShow(true);
        setPopupType({...popupType, idx: idx});
    }

    const onChangeAllCheck = (target: HTMLInputElement) => {
        setCheckList(new Array(checkList.length).fill(target.checked));
    };

    const onClickCheckBox = (idx: number) => {
        let copy = checkList.slice();
        copy[idx] = !copy[idx];
        setCheckList(copy);
    }

    return (
        <>
            <div id="AdminArticleMange">
                <h2 className="page_title">{props.title}</h2>
                <DashBoardListHead count={props.list.total_count} countTxt={"총 승인요청 기사 수"} initKeyword={props.keyword} searchType={props.searchType}
                                   setSearchType={props.setSearchType} searchAPI={props.searchList} onChangeAllCheck={onChangeAllCheck}
                />
                <table>
                    <caption className="ir_txt">기사 리스트</caption>
                    <colgroup>
                        {/*<col width="5%"/>*/}
                        <col width="5%"/>
                        <col width="45%"/>
                        <col width="5%"/>
                        <col width="10%"/>
                        <col width="10%"/>
                        <col width="5%"/>
                        <col width="20%"/>
                    </colgroup>
                    <thead>
                        <tr>
                            {/*<th>선택</th>*/}
                            <th>번호</th>
                            <th>기사 제목</th>
                            <th>기자명</th>
                            <th>등록일</th>
                            <th>업로드일</th>
                            <th>기사 작성</th>
                            <th>비고</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.list.items?.map((item: ItemType, idx: number) => {
                                return (
                                    <tr key={idx}>
                                        {/*<td>*/}
                                        {/*    <input type="checkbox" checked={Boolean(checkList[idx])} onChange={() => onClickCheckBox(idx)}/>*/}
                                        {/*</td>*/}
                                        <td>{props.list.total_count - idx - ((page - 1) * props.count)}</td>
                                        <td>
                                            <p className="ellipsis">{item.title}</p>
                                        </td>
                                        <td>{item.reporter_name}</td>
                                        <td>{window.$Global.convertDate(item.inserted_at)}</td>
                                        <td>{window.$Global.convertDate(item.created_at)}</td>
                                        <td>{convertArticleStateStr(item.section_state)}</td>
                                        <td>
                                            <div className="btns">
                                                <button className="btn_info" onClick={() => onClickPopupShow(item.idx)}>정보</button>
                                                <button className="btn_accept" onClick={() => confirmArticle(item.idx,  item.section_idx)}>승인</button>
                                                <button className="btn_del" onClick={() => rejectArticle(item.idx,  item.section_idx)}>거절</button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <Pagination curPage={page} lastNum={props.list.last} onClick={props.changePage} />
            </div>
            <ArticlePopup show={popupShow} setShow={setPopupShow} Component={() => <ReporterArticleAdd idx={popupType.idx} type={popupType.type}/>}/>
        </>
    )
}

export default withList(AdminArticleMange);