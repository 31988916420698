import {ArticleType, ArticleTypeStr} from "@models/article";

export function convertArticleTypeStr(state: number) {
    switch (state) {
        case ArticleType.E:
            return ArticleTypeStr.E;
        case ArticleType.S:
            return ArticleTypeStr.S;
        case ArticleType.G:
            return ArticleTypeStr.G;
        case ArticleType.Etc:
            return ArticleTypeStr.Etc;
        case ArticleType.Announcement:
            return ArticleTypeStr.Announcement;
    }
}