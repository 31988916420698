import React, {useState} from "react";
import AdminTaskRequestList from "./AdminTaskRequestList";
import AdminTaskRequestDetail from "./AdminTaskRequestDetail";

interface Props {
    title: string
}

function AdminTaskRequestContainer({title}: Props) {
    const [idx, setIdx] = useState(0);
    return (
        <div id="AdminTaskRequestContainer">
        {
            idx
            ? <AdminTaskRequestDetail idx={idx} funcBack={() => setIdx(0)}/>
            : <AdminTaskRequestList title={title} funcDetail={setIdx}/>
        }
        </div>
    )
}

export default AdminTaskRequestContainer;