import React, {useEffect, useState} from "react";
import "../css/AdminAnnouncementList.scss";
import DashBoardListHead from "../../common/DashBoardListHead";
import AdminAPI from "@api/admin";
import qs from "query-string";
import {useHistory, useLocation} from "react-router";
import Pagination from "../../common/Pagination";
import withList from "../../common/hoc/withList";
import {GetListType} from "../../common/interface/GetListType";
import {ArticleType, ListType} from "@models/article";
import {getOptionType} from "@api/interface/optionType";
import {useSelector} from "react-redux";
import {RootState} from "@app/store";
import message from "@const/message";
import ArticlePopup from "../../common/ArticlePopup";
import AdminAnnouncementAdd from "./AdminAnnouncementAdd";

interface Props extends GetListType<ListType> {
    title: string
}

function AdminAnnouncementList(props: Props) {
    const history = useHistory();
    const location = useLocation();
    const page = Number(qs.parse(location.search).page?.toString() || 1);
    const section = useSelector((state: RootState) => state.section);
    const large_section_idx = section.find(item => item.type === ArticleType.Announcement)?.idx;
    const [popupShow, setPopupShow] = useState(false);
    const [popupIdx, setPopupIdx] = useState(0);

    useEffect(() => {
        getList();
    }, [page, large_section_idx]);

    const getList = () => {
        let params: getOptionType = {
            page: page,
            count: props.count,
            large_section_idx: large_section_idx
        };
        if (props.keyword) {
            params.keyword = props.keyword;
            params.option = props.searchType;
        }
        AdminAPI.getArticleList(params).then((res: ListType) => props.setList(res));
    }

    const deleteArticle = (article_idx: number) => {
        if (window.confirm(message.delete)) {
            AdminAPI.deleteArticle(article_idx).then(() => history.go(0));
        }
    }

    const onClickDetail = (idx: number) => {
        setPopupShow(true);
        setPopupIdx(idx);
    }

    return (
        <>
            <div id="AdminAnnouncementList">
                <h2 className="page_title">{props.title}</h2>
                <DashBoardListHead count={props.list.total_count} countTxt={"총 공시 수"} initKeyword={props.keyword} searchType={props.searchType}
                                   setSearchType={props.setSearchType} searchAPI={props.searchList}
                />
                <table>
                    <caption className="ir_txt">업무 요청 목록</caption>
                    <colgroup>
                        <col width="10%"/>
                        <col width="55%"/>
                        <col width="10%"/>
                        <col width="10%"/>
                        <col width="15%"/>
                    </colgroup>
                    <thead>
                    <tr>
                        <th>번호</th>
                        <th>제목</th>
                        <th>작성인</th>
                        <th>입력일자</th>
                        <th>비고</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        props.list.items?.map((item, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{props.list.total_count - idx - ((props.page - 1) * props.count)}</td>
                                    <td>{item.title}</td>
                                    <td>{item.reporter_name}</td>
                                    <td>{window.$Global.convertDate(item.created_at)}</td>
                                    <td>
                                        <div className="btns">
                                            <button className="btn_info" onClick={() => onClickDetail(item.idx)}>정보</button>
                                            <button className="btn_del" onClick={() => deleteArticle(item.idx)}>삭제</button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
                <Pagination curPage={page} lastNum={props.list.last} onClick={props.changePage} />
            </div>
            <ArticlePopup show={popupShow} setShow={setPopupShow} Component={() => <AdminAnnouncementAdd isView={true} idx={popupIdx}/>}/>
        </>
    )
}

export default withList(AdminAnnouncementList);