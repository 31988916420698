import React, {useEffect, useState} from "react";
import "../css/AdminUserManage.scss";
import DashBoardListHead from "../../common/DashBoardListHead";
import {useLocation} from "react-router";
import qs from "query-string";
import AdminAPI from "@api/admin";
import Pagination from "../../common/Pagination";
import {MemberListType} from "@models/admin";
import {GetArticleOptionType} from "@models/article";
import AdminMemberInfoPopup from "./AdminMemberInfoPopup";
import message from "@const/message";
import {convertRankStateStr} from "../../common/js/convertRankStateStr";
import {MediaInfoType} from "@models/member";
import withList from "../../common/hoc/withList";
import {GetListType} from "../../common/interface/GetListType";

interface Props extends GetListType<MemberListType> {
    title: string
}

function AdminUserManage(props: Props) {
    const location = useLocation();
    const page = Number(qs.parse(location.search).page?.toString() || 1);
    const [popupShow, setPopupShow] = useState(false);
    const [editType, setEditType] = useState(false);
    const [selectMemberIdx, setSelectMemberIdx] = useState(0);

    useEffect(() => {
        getList();
    }, [page]);

    const getList = () => {
        let params: GetArticleOptionType = {
            page: page,
            count: props.count,
        };
        if (props.keyword) {
            params.keyword = props.keyword;
            params.option = props.searchType;
        }
        AdminAPI.getMediaMemberList(params).then((res: MemberListType) => props.setList(res));
    };

    const onClickShowInfo = (idx: number, isEdit: boolean) => {
        setPopupShow(true);
        setSelectMemberIdx(idx);
        setEditType(isEdit);
    }

    const onClickDelete = (idx: number) => {
        if (window.confirm(message.delete)) AdminAPI.deleteMediaMember(idx).then(() => getList());
    }

    return (
        <>
            <div id="AdminUserManage">
                <h2 className="page_title">{props.title}</h2>
                <DashBoardListHead count={props.list.total_count} countTxt={"총 회원 수"} countUnit={"명"} option={[{text: '회원명', value: 0}]}
                                   initKeyword={props.keyword} searchType={props.searchType} setSearchType={props.setSearchType} searchAPI={props.searchList}
                />
                <table>
                    <colgroup>
                        {/*<col width="5%"/>*/}
                        <col width="10%"/>
                        <col width="15%"/>
                        <col width="20%"/>
                        <col width="20%"/>
                        <col width="10%"/>
                        <col width="25%"/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>번호</th>
                            <th>회원명</th>
                            <th>ID</th>
                            <th>이메일</th>
                            <th>회원 등급</th>
                            <th>관리</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        props.list.items?.map((item, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{props.list.total_count - idx - ((page - 1) * props.count)}</td>
                                    <td>{item.name}</td>
                                    <td>{item.id}</td>
                                    <td>{item.email}</td>
                                    <td>{convertRankStateStr(item.rank)}</td>
                                    <td>
                                        <div className="btns">
                                            <button className="btn_info" onClick={() => onClickShowInfo(item.idx, false)}>정보</button>
                                            {
                                                !(item.rank === MediaInfoType.mediaMaster)
                                                &&
                                                <>
													<button className="btn_edit ir_txt" onClick={() => onClickShowInfo(item.idx, true)}>수정 버튼</button>
													<button className="btn_del" onClick={() => onClickDelete(item.idx)}>삭제</button>
                                                </>
                                            }
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
                <Pagination curPage={page} lastNum={props.list.last} onClick={props.changePage} />
            </div>
            <AdminMemberInfoPopup show={popupShow} onClickHide={() => setPopupShow(false)} idx={selectMemberIdx} isEdit={editType} callback={getList}/>
        </>
    )
}

export default withList(AdminUserManage);