import API from '../api';
import { GetArticleOptionType } from "@models/article";
import {getOptionType} from "../interface/optionType";
import {TaskRequestAddType} from "@models/taskRequest";
import {UpdateSectionListType} from "@models/section";

export default {
    getArticleList: (params: GetArticleOptionType) => API.get(`/media/manager/article`, params),
    getArticle: (article_idx: number) => API.get(`/media/manager/article/${article_idx}`),
    confirmArticle: (article_idx: number, section_idx: number) => API.post(`/media/manager/article/${article_idx}/section/${section_idx}/confirm`),
    rejectArticle:  (article_idx: number, section_idx: number) => API.delete(`/media/manager/article/${article_idx}/section/${section_idx}`),
    getMediaMemberList: (params: GetArticleOptionType) => API.get(`/media/manager/member`, params),
    getMediaMemberInfo: (member_idx: number) => API.get(`/media/manager/member/${member_idx}`),
    deleteMediaMember: (member_idx: number) => API.delete(`/media/manager/member/${member_idx}`),
    updateMediaMemberInfo: (member_idx: number, rank: number) => API.patch(`/media/manager/member/${member_idx}`, {rank}),
    getRequest: (param: getOptionType) => API.get(`/media/manager/request`, param),
    addRequest: (payload: TaskRequestAddType) => API.post('/media/manager/request', payload),
    getRequestInfo: (request_idx: number) => API.get(`/media/manager/request/${request_idx}`),
    addSection: (type: number, name: string, parent_idx?: number, sort?: number) => API.post('/media/manager/section', {type, name, parent_idx, sort}),
    updateSection: (section_idx: number, name: string, order: number) => API.patch(`/media/manager/section/${section_idx}`, {name, order}),
    updateSectionList: (payload: UpdateSectionListType) => API.patch('/media/manager/section/list', payload),
    deleteSection: (section_idx: number) => API.delete(`/media/manager/section/${section_idx}`),

    addArticle: (payload: FormData) => API.post(`/media/manager/article`, payload),
    updateArticle: (article_idx: number, payload: FormData) => API.patch(`/media/manager/article/${article_idx}`, payload),
    uploadArticleImage: (payload: Object) => API.post('/article/image', payload),
    deleteArticle: (article_idx: number) => API.delete(`/media/manager/article/${article_idx}`),
    deleteArticleList: (payload: number[]) => API.delete(`/media/manager/article/list`, {article_idx_list: payload}),
}