import React, {useState} from "react";
import {Link} from "react-router-dom";
import "./css/SideArticleListCategory.scss";
import {ChildType} from "@models/home";
import {ItemType} from "@models/article";
import {getSectionURL} from "./js/getSectionURL";
import {getArticleURL} from "./js/getArticleURL";

interface Props {
    list: ChildType
}

function SideArticleListCategory({list}: Props) {
    const [tabActive, setTabActive] = useState(0);
    const sectionURL = getSectionURL(list?.idx, list?.child[tabActive]?.idx);
    const hasChild = list?.child.length > 0;
    let viewList:ItemType[] = [];

    if (hasChild) {
        viewList = list?.child[tabActive].article;
    }

    return (
        <div id="SideArticleListCategory">
            <div className="side_area">
                <div className="side_head">
                    {
                        list?.child.map((item, idx) => <button key={idx} className={tabActive === idx ? 'active' : ''} onClick={() => setTabActive(idx)}>{item.name}</button>)
                    }
                </div>
                <div className="news">
                    {
                        viewList?.map(item => {
                            return (
                                <Link key={item.idx} to={getArticleURL(item.idx, item.section_idx)} className="item">
                                    <img src={window.$Global.getImageCDN(item.thumbnail_key)} alt="기사 이미지" />
                                    <p>{item.title}</p>
                                </Link>
                            )
                        })
                    }
                </div>
                <Link to={sectionURL} className="btn_more ir_txt">기사 더보기 버튼</Link>
            </div>
        </div>
    )
}

export default SideArticleListCategory;