import variable from "../const/variable";
import {AxiosResponse} from "axios";

export default {
    getParams() {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        return params;
    },

    convertDate(date:number, defaultValue?: string, dist?: string) {
        if (!date) return defaultValue || "";
        dist = dist ? dist : '.';
        let convertDate = new Date(Number(date));
        return convertDate.getFullYear() + dist + (convertDate.getMonth() + 1).toString().padStart(2, '0') + dist + convertDate.getDate().toString().padStart(2, '0');
    },

    convertTime(date:number, defaultValue?: string) {
        if (!date) return defaultValue || "";
        let convertDate = new Date(Number(date));
        return convertDate.getFullYear() + '-' + (convertDate.getMonth() + 1).toString().padStart(2, '0') + '-' + convertDate.getDate().toString().padStart(2, '0') + " " + convertDate.getHours().toString().padStart(2, '0') + ":" + convertDate.getMinutes().toString().padStart(2, '0')
    },

    convertFileSize(bytes: number, dp=1) {
        const thresh = 1000;
        const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const r = 10**dp;
        let u = -1;

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

        return bytes.toFixed(dp) + ' ' + units[u];
    },

    getImageCDN(url: string) {
        return variable.imageCDN + url;
    },

    removeHTML(str: string) {
        return str.replace(/<[^>]*>/g, '');
    },

    readImage(data: Blob | File) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onerror = reject;
            reader.onload = () => {
                let img = new Image();
                img.onerror = reject;
                img.onload = () => {
                    resolve(reader.result);
                };
                if (typeof reader.result === "string") {
                    img.src = reader.result;
                }
            };
            if(typeof data == "object") {
                reader.readAsDataURL(data);
            } else {
                // data가 파일 객체가 아니라 data uri일 경우
                reader.readAsDataURL(this.dataURItoBlob(data));
            }
        });
    },

    dataURItoBlob(dataURI: string) {
        let byteString = atob(dataURI.split(',')[1]);
        let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], {type: mimeString});
    },

    numberRegx(str: string) {
        let regexp = /[^0-9]/g;

        if (str.length === 0) {
            str = str.substring(1);
        }
        return str.replace(regexp,'');
    },

    isEmptyObject(param: Object) {
        return Object.keys(param).length === 0 && param.constructor === Object;
    },

    
    // @param {exceptionPageKey} 검색, 페이징에 사용되는 키 포함할지 여부
    // @param {exceptionKeyList} 제외 리스트에 추가할 키 목록
    getURLQueryKeep(exceptionPageKey?: boolean, exceptionKeyList?: string[]) {
        const params = window.$Global.getParams();
        const keys = Object.keys(params);
        const defaultKeyList = ['option', 'keyword', 'page'];
        exceptionKeyList = exceptionKeyList || [];
        const exceptionKey = exceptionPageKey ? defaultKeyList.concat(exceptionKeyList) : ['option', 'keyword', 'page'];
        let commonQuery = '?';

        keys.map((item, idx) => {
            if (!exceptionKey.find(item2 => item === item2)) {
                if (idx > 0) {
                    commonQuery += '&';
                }
                commonQuery += `${item}=${params[item]}`;
            }
        });

        if (commonQuery !== '?') {
            commonQuery += '&';
        }
        return commonQuery;
    },

    setFormData(formData: FormData, obj: any) {
        let keys = Object.keys(obj);

        keys.forEach((item: string) => {
            let value = obj[item as keyof typeof obj];
            let type = typeof value;
            let isArray = Array.isArray(value);

            if (isArray) {
                let fileList = value as File[];
                let fileType = fileList[0] instanceof File;
                if (fileType) {
                    fileList.forEach(file => formData.append(item, file));
                    return;
                }
            }

            switch (type) {
                case "number" :
                    value = value.toString();
                    break;
                case "object" :
                    value = JSON.stringify(value);
                    break;
                case "undefined" :
                    value = "";
                    break;
            }
            formData.append(item, value as string);
        });
    },

    download(res: AxiosResponse) {
        let req = res.headers;
        let blob = res.data;
        let filename = "";

        let disposition = req['content-disposition'];
        if (disposition && disposition.indexOf('attachment') !== -1) {
            let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            let matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
        }

        if (typeof window.navigator.msSaveBlob !== 'undefined') {
            window.navigator.msSaveBlob(blob, filename);
        } else {
            let URL = window.URL || window.webkitURL;
            let downloadUrl = URL.createObjectURL(new Blob([blob]));

            if (filename) {
                let a = document.createElement("a");
                if (typeof a.download === 'undefined') {
                    window.location.href = downloadUrl;
                } else {
                    a.href = downloadUrl;
                    a.download = decodeURIComponent(filename);
                    document.body.appendChild(a);
                    a.click();
                }
            } else {
                window.location.href = downloadUrl;
            }

            setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100); // cleanup
        }
    },

    sizeCal(imageUrl: string, fixHeight: number) {
        // 고정된 높이에 이미지 사이즈를 동적으로 조절하는 함수
        // 계산식 이미지 너비와 높이의 비율 계산 후 나온 비율로 고정 높이에 곱해주면 됨.
        const img = new Image();
        img.src = imageUrl;

        let convertWidth;
        let rate = Math.floor(img.width > img.height ? img.width / img.height : img.height / img.width);
        convertWidth = fixHeight * rate;

        return { width: convertWidth || 161465/0 + "px", height: fixHeight + "px" };
    }
}