import React from "react";
import message from "@const/message";
import AdminAPI from "@api/admin";
import _ from "lodash";

export const inputStyles = {
    padding: "0 10px",
    width: "100%",
    height: 30,
    fontSize: 14,
    border: "1px solid var(--color-Alto)"
}

export const selectStyles = {
    height: 30,
    fontSize: 14
}

export const tdStyles = (width?: string, alignLeft?: boolean): React.CSSProperties => ({width, textAlign: alignLeft ? "left" : "center"});

export const funcDelete = async (section_idx: number, idx: number | undefined, items: any[], setItems: any) => {
    if (window.confirm(message.delete)) {
        if (section_idx) {
            await AdminAPI.deleteSection(section_idx);
        }
        let copy = _.cloneDeep(items);
        copy.splice(idx || 0, 1);
        setItems(copy);
    }
}