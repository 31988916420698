import React, {useEffect} from "react";
import "./css/Section.scss";
import { RootState } from '@app/store'
import { useSelector } from 'react-redux'
import {useParams} from "react-router-dom";
import SectionSide from "./SectionSide";
import ArticleList from "../common/ArticleList";
import {MediaSectionType} from "@models/media";
import MediaAPI from "@api/media";
import {ArticleType, ListType} from "@models/article";
import qs from "query-string";
import {useHistory, useLocation} from "react-router";
import {getOptionType} from "@api/interface/optionType";
import Pagination from "../common/Pagination";
import AnnouncementBoardTable from "../announcement/AnnouncementBoardTable";
import withList from "../common/hoc/withList";
import {GetListType} from "../common/interface/GetListType";
import {Tab, Tabs} from "@mui/material";
import {getSectionURL} from "../common/js/getSectionURL";

function Section(props: GetListType<ListType>) {
    const history = useHistory();
    const location = useLocation();
    const section = useSelector((state: RootState) => state.section);
    const articleType = qs.parse(location.search).articleType;
    const page = Number(qs.parse(location.search).page?.toString() || 1);
    const {parent_idx, child_idx} = useParams<{parent_idx: string, child_idx: string}>();
    const childSection:MediaSectionType = section.find(item => item.idx === Number(parent_idx)) as MediaSectionType;
    const isAnnouncement = Number(articleType) === ArticleType.Announcement;
    const [value, setValue] = React.useState(0);

    useEffect(() => {
        getArticle();
    }, [parent_idx, child_idx, page]);

    useEffect(() => {
        if (section.length > 0 && articleType === undefined) {
            let type = section.find(item => item.idx === Number(parent_idx))?.child.find(item => item.idx === Number(child_idx))?.type;
            history.push(`${location.pathname}${window.$Global.getURLQueryKeep()}articleType=${type}`);
        }
        let selectTabIndex = childSection?.child?.findIndex(item => item.idx === Number(child_idx));
        setValue(selectTabIndex || 0);
    }, [location]);

    const getArticle = async () => {
        let params: getOptionType = {
            page: page,
            count: props.count
        };
        MediaAPI.getMediaArticleList(Number(parent_idx), Number(child_idx), params).then((res: ListType) => props.setList(res));
    };

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
    };

    return (
        <div id="Section">
            <div className="main_content">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    scrollButtons={false}
                    variant="scrollable"
                    aria-label="scrollable auto tabs"
                >
                    {
                        childSection?.child.map((item, idx) => {
                            return <Tab key={idx} label={item.name} onClick={() => history.push(getSectionURL(Number(parent_idx), item.idx))} />
                        })
                    }

                </Tabs>
                {/*<ul className="tab">*/}
                {/*{*/}
                {/*    childSection?.child.map((item, idx) => {*/}
                {/*        let classname = '';*/}
                {/*        if (child_idx) {*/}
                {/*            if (item.idx === Number(child_idx)) classname = 'active';*/}
                {/*        } else {*/}
                {/*            if (idx === 0) classname = 'active';*/}
                {/*        }*/}
                {/*        return (*/}
                {/*            <li key={item.idx} className={classname}>*/}
                {/*                <Link to={`${path.section}/${parent_idx}/${item.idx}`}>{item.name}</Link>*/}
                {/*            </li>*/}
                {/*        )*/}
                {/*    })*/}
                {/*}*/}
                {/*</ul>*/}
                {
                    isAnnouncement
                    ? <AnnouncementBoardTable list={props.list.items}/>
                    : <ArticleList list={props.list.items}/>
                }
                <Pagination curPage={page} lastNum={props.list.last} onClick={props.changePage} />
            </div>
            <SectionSide textData={props.list.recent} imgTextData={props.list.popular}/>
        </div>
    )
}

export default withList(Section);