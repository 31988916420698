import {useEffect, useState} from "react";

function useMobileCheck() {
    const isClient = typeof window === 'object';
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (!isClient) return;
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getSize = () => {
        return {
            width: isClient ? window.innerWidth : 0,
            height: isClient ? window.innerHeight : 0
        };
    }

    const handleResize = () => {
        setIsMobile(getSize().width < (758 - 1));
    }

    return isMobile;
}

export default useMobileCheck;