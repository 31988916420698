import UserInfoEdit from "@components/user/UserInfoEdit";
import AdminUserManage from "@components/admin/user/AdminUserManage";
import AdminArticleMange from "@components/admin/article/AdminArticleMange";
import AdminTaskRequestAdd from "@components/admin/taskRequest/AdminTaskRequestAdd";
import ReporterArticleAdd from "@components/reporter/ReporterArticleAdd";
import ReporterNewsSaveList from "@components/reporter/ReporterArticleSaveList";
import ReporterNewsList from "@components/reporter/ReporterArticleList";
import {MediaInfoType} from "@models/member";
import AdminTaskRequestContainer from "@components/admin/taskRequest/AdminTaskRequestContainer";
import AdminAnnouncementList from "@components/admin/announcement/AdminAnnouncementList";
import AdminAnnouncementAdd from "@components/admin/announcement/AdminAnnouncementAdd";
import AdminSectionSetting from "@components/admin/section/AdminSectionSetting";
import JoinMediaSignIn from "@components/joinMedia/JoinMediaSignIn";
import JoinMediaList from "@components/joinMedia/JoinMediaList";

export const dashBoardTab = {
    [MediaInfoType.mediaMaster]: {
        title: "관리자 대시보드",
        list: [
            {
                title: "마이페이지",
                child: ["정보 수정"],
                component: [UserInfoEdit],
            },
            {
                title: "회원 관리",
                child: ["회원 리스트"],
                component: [AdminUserManage],
            },
            {
                title: "미디어 기사 관리",
                child: ["미디어 기사 리스트"],
                component: [AdminArticleMange],
            },
            {
                title: "가입 미디어 관리",
                child: ["가입한 미디어", "미디어 가입"],
                component: [JoinMediaList, JoinMediaSignIn],
            },
            // {
            //     title: "기사 관리",
            //     child: ["기사 작성", "임시 저장 기사", "기사 리스트"],
            //     component: [ReporterArticleAdd, ReporterNewsSaveList, ReporterNewsList],
            // },
            {
                title: "기사 관리",
                child: ["기사 작성"],
                component: [ReporterArticleAdd],
            },
            {
                title: "공시 관리",
                child: ["공시 리스트", "공시 작성"],
                component: [AdminAnnouncementList, AdminAnnouncementAdd],
            },
            {
                title: "업무 요청",
                child: ["업무 요청 리스트", "업무 요청 작성"],
                component: [AdminTaskRequestContainer, AdminTaskRequestAdd],
            },
            {
                title: "섹션 관리",
                child: ["섹션 설정"],
                component: [AdminSectionSetting],
            },
        ]
    },
    [MediaInfoType.reporter]: {
        title: "기자 대시보드",
        list: [
            {
                title: "마이페이지",
                child: ["정보 수정"],
                component: [UserInfoEdit],
                // child: ["정보 수정", "다른 미디어 가입하기", "가입 대기중인 미디어"],
                // component: [UserInfoEdit, UserMediaJoin, UserMediaMyJoinList],
            },
            {
                title: "가입 미디어 관리",
                child: ["가입한 미디어", "미디어 가입"],
                component: [JoinMediaList, JoinMediaSignIn],
            },
            {
                title: "기사 관리",
                child: ["기사 작성", "임시 저장 기사", "기사 리스트"],
                component: [ReporterArticleAdd, ReporterNewsSaveList, ReporterNewsList],
            },
        ]
    },

    [MediaInfoType.normalUser]: {
        title: "대시보드",
        list: [
            {
                title: "마이페이지",
                child: ["정보 수정"],
                component: [UserInfoEdit],
            },
            {
                title: "가입 미디어 관리",
                child: ["가입한 미디어", "미디어 가입"],
                component: [JoinMediaList, JoinMediaSignIn],
            },
        ]
    }
}