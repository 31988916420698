import React, {useEffect, useRef, useState} from "react";
import message from "@const/message";
import {Article, ArticleType, ImageItem} from "@models/article";
import {useHistory} from "react-router";
import AdminAPI from "@api/admin";
import {useSelector} from "react-redux";
import {RootState} from "@app/store";
import ArticleContainer from "../../common/ArticleContainer";
import { checkArticle, setCommonFormData } from "../../common/js/funcArticleAdd";

interface Props {
    title?: string
    isView: boolean
    idx?: number
    editorHeight?: string
}

function AdminAnnouncementAdd(props: Props) {
    const history = useHistory();
    const section = useSelector((state: RootState) => state.section);
    const [article, setArticle] = useState<Article>({} as Article);

    const [uploadImageList, setUploadImageList] = useState<ImageItem[]>([]); // 서버에 저장된 이미지 목록
    const [titleRef, writerRef] = [useRef(null), useRef(null)];
    const refs = [titleRef, writerRef];

    useEffect(() => {
        if (props.idx) {
            AdminAPI.getArticle(props.idx).then((res: Article) => initData(res));
        }
    }, [props.idx]);
    
    useEffect(() => {
        let section_idx = Number(section.find(item => item.type === ArticleType.Announcement)?.idx) || 0;
        setArticle({...article, section_list: [{large_section_idx: section_idx, medium_section_idx: 0}]});
    }, [section]);

    const initData = (data: Article) => {
        data.image_list?.forEach((item: ImageItem) => item.file_key = window.$Global.getImageCDN(item.file_key));
        data.thumbnail_key = window.$Global.getImageCDN(data.thumbnail_key);
        setUploadImageList(data.image_list);
        setArticle(data);
    }

    const articleAdd = async () => {
        if (checkArticle(article, refs)) {
            let formData = new FormData();
            await setCommonFormData(formData, article, uploadImageList);

            await AdminAPI.addArticle(formData).then(() => {
                alert(message.successADD);
                history.go(0);
            });
        }
    };

    const fieldObj = [
        {
            title: "공시제목",
            type: "text",
            className: "full_size",
            placeholder: true,
            key: "title" as keyof Article,
            ref: titleRef
        },
        {
            title: "작성인",
            type: "text",
            className: "full_size",
            placeholder: true,
            key: "writer" as keyof Article,
            ref: writerRef
        },
        {
            title: "입력일자",
            type: "date",
            placeholder: false,
            key: "inserted_at" as keyof Article,
            ref: null
        },

    ];

    const BtnComponents = <button className="btn_request" onClick={articleAdd}>등록</button>

    return (
        <div id="AdminAnnouncementAdd">
            <ArticleContainer title={props.title} isView={props.isView} article={article} setArticle={setArticle} fieldObj={fieldObj} largeSelectDisabled={true} BtnComponents={BtnComponents}/>
        </div>
    )
}

export default AdminAnnouncementAdd;