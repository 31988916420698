import React from "react";
import "./css/ArticleContainer.scss";
import EditorLibrary from "../common/EditorLibrary";
import ArticleForm from "../common/ArticleForm";
import {ArticleFormType} from "./interface/ArticleAddType";

// fieldObj 입력 받을 input JSON array
// largeSelectDisabled: 대분류 섹션 비활성화 여부
// BtnComponents 레이아웃 내 엘리먼트 컴포넌트
function ArticleContainer({title, isView, article, setArticle, fieldObj, largeSelectDisabled, BtnComponents}: ArticleFormType) {
    return (
        <div id="ArticleContainer">
            <ArticleForm title={title}
                         isView={isView}
                         article={article} setArticle={setArticle}
                         fieldObj={fieldObj}
                         largeSelectDisabled={largeSelectDisabled}
                         BtnComponents={BtnComponents}
            />
            <EditorLibrary isView={isView} article={article} setArticle={setArticle}/>
        </div>
    )
}

export default ArticleContainer;