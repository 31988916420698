export interface TaskRequestAddType {
    title: string
    content: string
}


export interface TaskRequestType extends TaskRequestAddType{
    idx: number
    media_idx: number
    comment: string
    id: string
    name: string
    state: 0 | 1 | 2
    deleted: number
    created_at: number
}

export enum State {
    Wait = 0,
    WaitStr = "답변 대기중",
    Success = 1,
    SuccessStr = "답변 완료",
    Reject = 2,
    RejectStr = "답변 거절",
}
