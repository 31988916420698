import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {CompanyInfoType} from "@models/companyInfo";

const initialState: CompanyInfoType = {} as CompanyInfoType;

export const mediaInfoSlice = createSlice({
    name: 'mediaInfo',
    initialState,
    reducers: {
        setMediaInfo: (state, action: PayloadAction<CompanyInfoType>) => {
            return action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setMediaInfo } = mediaInfoSlice.actions

export default mediaInfoSlice.reducer