import React, {useEffect, useState} from "react";
import "../css/AdminSectionSettingPopup.scss";
import {arrayMove, List} from "react-movable";
import {MediaSectionChildType, MediaSectionType} from "@models/media";
import message from "@const/message";
import AdminAPI from "@api/admin";
import {inputStyles, tdStyles} from "./common/setting";
import _ from "lodash";

interface Props {
    targetSection: MediaSectionType
    section: MediaSectionType[]
    setSection: (prop: MediaSectionType[]) => void
    onClose: () => void
}

function AdminSectionSettingPopup(props: Props) {
    const [widths, setWidths] = React.useState<string[]>([]);
    const [items, setItems] = React.useState<MediaSectionChildType[]>(_.cloneDeep(props.targetSection?.child || []));
    const [largeCategory, setLargeCategory] = useState(props.targetSection.name);

    useEffect(() => {
        setItems(props.targetSection?.child || []);
    }, [props.targetSection]);

    const onChangeChildSection = (name: string, index: number | undefined) => {
        let copy = _.cloneDeep(items);
        copy[index || 0] = { ...copy[index || 0], name } as MediaSectionChildType;
        setItems(copy);
    }

    const onClickAddField = () => {
        setItems(items.concat({} as MediaSectionChildType));
    }

    const onClickAdd = async () => {
        let copy = _.cloneDeep(items);
        copy.forEach((item, idx) => {
            item.order = idx + 1;
        });

        let payload = {
            section_idx: props.targetSection.idx,
            name: largeCategory,
            child: copy.filter(item => item.name)
        }

        AdminAPI.updateSectionList(payload).then(() => {
            let copySection = _.cloneDeep(props.section);
            let updateTarget = copySection.find(item => item.idx === props.targetSection.idx) as MediaSectionType;

            updateTarget.name = largeCategory;
            updateTarget.child = copy;

            alert(message.successSave);
            props.setSection(copySection);
            props.onClose();
        })
    }

    const onClickDelete = async (section_idx: number, idx: number | undefined) => {
        if (window.confirm(message.delete)) {
            let copy = _.cloneDeep(items);
            let index = section_idx ? (copy.findIndex(item => item.idx === section_idx) || 0) : idx || 0;
            copy.splice(index, 1);
            setItems(copy);
        }
    }

    return (
        <div id="AdminSectionSettingPopup">
            <div className="head">
                <h2 className="page_title">섹션 수정</h2>
                <button className="icon_exit ir_txt" onClick={props.onClose}>닫기 버튼</button>
            </div>
            <div className="category">
                <label htmlFor="large_category">대분류 섹션</label>
                <input type="text" id="large_category" value={largeCategory} onChange={e => setLargeCategory(e.target.value)}/>
            </div>

            <button className="btn_add" onClick={onClickAddField}>
                <i className="icon_posting"/>
                <span>섹션 추가하기</span>
            </button>

            <List
                beforeDrag={({ elements, index }) => {
                    const cells = Array.from(elements[index].children);
                    const widths = cells.map(cell => window.getComputedStyle(cell).width);
                    setWidths(widths);
                }}
                values={items}
                onChange={({ oldIndex, newIndex }) => setItems(arrayMove(items, oldIndex, newIndex))}
                renderList={({ children, props, isDragged }) => (
                    <table>
                        <thead>
                            <tr>
                                <th className="order">게시순서</th>
                                <th className="section_name">섹션명</th>
                                <th className="manage">관리</th>
                            </tr>
                        </thead>
                        <tbody {...props}>{children}</tbody>
                    </table>
                )}
                renderItem={({ value, index, props, isDragged, isSelected }) => {
                    const _widths = isDragged ? widths : [];
                    const _btnShow = isDragged ? { opacity: 0 } : { opacity: 1 };
                    const row = (
                        <tr
                            {...props}
                            style={{
                                ...props.style,
                                cursor: isDragged ? 'grabbing' : 'inherit',
                                backgroundColor: isDragged || isSelected ? '#EEE' : '#fafafa'
                            }}
                        >
                            <td className="order" style={tdStyles(_widths[0])}>{(index || 0) + 1}</td>
                            <td className="section_name" style={tdStyles(_widths[1], true)}>
                                <input type="text" style={inputStyles} value={value.name} onChange={e => onChangeChildSection(e.target.value, index)}/>
                            </td>
                            <td className="manage" style={{...tdStyles(_widths[2]), ..._btnShow}}>
                                <div className="btns">
                                    <button className="btn_move" data-movable-handle>이동</button>
                                    <button className="btn_delete" onClick={() => onClickDelete(value.idx, index)}>삭제</button>
                                </div>
                            </td>
                        </tr>
                    );
                    return isDragged ? (
                        <table style={{ ...props.style, borderSpacing: 0 }}>
                            <tbody>{row}</tbody>
                        </table>
                    ) : row;
                }}
            />
            <button className="btn_save" onClick={onClickAdd}>저장</button>
        </div>
    )
}

export default AdminSectionSettingPopup;