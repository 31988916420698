import React from "react";
import {Route} from "react-router-dom";

interface Props {
    path: string
    Component: (props: any) => React.ReactElement
}

function RouteExcept({path, Component, ...parentProps}: Props) {
    const exceptPath = ['/signup', '/myPage'];
    return (
        <Route
            {...parentProps}
            path={path}
            render={props => {
                const pathname = props.history.location.pathname;
                let result = exceptPath.findIndex(item => item === pathname) === -1;
                return (
                    result
                    ? <Component {...props}/>
                    : null
                )
            }}
        />
    )
}

export default RouteExcept;